<template>
  <div class="align wrapper-test section-paragraph">This is a test v10
    <div v-if="loading">Loading Please wait...</div>
    <div v-else>{{ info.value.data }}</div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import axios from 'axios';

export default {
  setup() {
    const info = reactive([]);
    const loading = ref(true);

    onMounted(() => {
      fetchData();
    });

    const fetchData = async() => {
      await axios.get("api/test").then((response) => {
        info.value = response;
        console.log(info.value.data);
        loading.value = false;
      });
    };

    return {
      fetchData,
      info,
      loading
    };
  },
};
</script>

<style scoped>
@import "../styles/globalStyles.scss";
.wrapper-test {
  height: 800px;
  margin-top: 58px;
}
</style>
